.myfooter {
    background-color: #004d60;
    border-top: 4px solid #009c8f;
    color: white
}

.myfooter > div > div > h4 {
    color: white !important;
}

.myfooter > div > div > h4::after {
    content: "";
    display: block;
    width: 32px;
    border-bottom: 3px solid #009c8f;;
    padding-bottom: 2px;
    margin-bottom: 8px;
}

.myfooter > p {
    color: white;
}
