body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flexlayout__layout {
    left: auto;
    top: 64px;
    right: auto;
    bottom: auto;
    /*width: calc(100% - 200px);*/
    width: 100%;
    height: calc(100% - 64px);
    font-family: 'Roboto', sans-serif;
}

.flexlayout__splitter {
    background-color: #fff;
}

.flexlayout__tab {
    box-shadow: 0 0 8px #ccc;
    z-index: 0;
}

.flexlayout__tabset_tabbar_outer_top {
    background-color: #eee;
    border-width: 0;
}

.flexlayout__tabset_tabbar_inner_tab_container {
    padding-left: 8px;
}

.flexlayout__tabset-selected {
    background-color: #fff;
}

.flexlayout__tabset {
    font-family: 'Roboto', sans-serif;
}

.flexlayout__tab_button--selected {
    background-color: #fff;
    z-index: 100;
    border-width: 3px 3px 3px 3px;
    border-top: #5f86c4;
    border-color: #009c8f;
    border-top-style: none;
    border-bottom-style: solid;
}

.flexlayout__tab_button--unselected {
    background-color: #fff;
    margin-top: 2px;
    /*box-shadow: inset 0 -3px 3px #888;*/
}


#root {
    height: 100%;
}

.ant-layout {
    height: 100%;
}

.site-layout-background {
    background: #fff;
}

.space-align-block {
    flex: none;
    height: 100%;
    margin-top: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    #border: 1px solid #40a9ff;
}

.ant-menu-vertical > .ant-menu-item {
    height: 25px;
    line-height: 25px;
}

.maxi-table .ant-table-cell {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.mini-table .ant-table-cell {
    padding: 0 4px !important;
}

.mini-table {
    margin-top: 4px;
}

.mini-table > div > div > div > div > div > table > tbody > tr > td {
    border-bottom: none !important;
    border-top: none !important;
}

th {
    font-weight: normal !important;
}

.ant-pagination {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.ant-pagination-prev {
    margin-left: auto !important;
}

/* Login form demo */

#components-form-demo-normal-login .login-form {
    max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
    float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
}

#components-form-demo-normal-login .login-form-button {
    width: 100%;
}

/* Forms */

.ant-select-selector,
div > .ant-input,
.ant-input-group > .ant-input,
.ant-input-affix-wrapper,
.ant-picker,
.ant-input-number {
    border-width: 0 0 2px !important;
    padding-bottom: 0 !important;
    border-style: dashed !important;
    border-radius: 0 !important;
}

.ant-input-group-compact > a {
    margin-top: -1px;
    margin-left: 1px;
    height: 30px
}

.ant-input-group-compact > button {
    margin-top: -1px;
    margin-left: 1px;
    height: 30px
}

.ant-input-group-compact > a > button {
    height: 30px
}

.ant-input-number-input-wrap {
    margin-top: 0 !important;
    margin-bottom: -2px !important;
}

.ant-select-selection-overflow {
    margin-top: 2px !important;
    margin-bottom: -2px !important;
    padding-bottom: 4px !important;
}

.ant-select-selection-item {
    margin-top: 2px !important;
    margin-bottom: -2px !important;
    padding-bottom: 4px !important;
}

.ant-form-item-explain-error {
    color: red;
}

.ant-pagination-options-size-changer > .ant-select-selector {
    height: 28px !important;
}

.ant-table {
    margin-bottom: 4px;
}

button:focus {
    #background-color: red !important;
    outline: 4px #ef9500 solid !important;
}

/* Global Search Field */

.globalsearch > .ant-input-wrapper > .ant-input-affix-wrapper {
    border-radius: 16px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-style: solid !important;
    border-bottom-width: 2px !important;
    color: #009c8f;
    border-color: #009c8f;
    background-color: #001628;
    padding-right: 0;
}

.globalsearch > .ant-input-wrapper > .ant-input-affix-wrapper > .ant-input-prefix {
    padding-top: 4px;
    padding-bottom: 4px;
}

.globalsearch > .ant-input-wrapper > .ant-input-affix-wrapper > .ant-input-suffix > .ant-input-clear-icon {
    color: #009c8f !important;
}

.globalsearch > .ant-input-wrapper > .ant-input-affix-wrapper > .ant-input {
    color: #009c8f;
    background-color: #001628;
}

.globalsearch > .ant-input-wrapper > .ant-input-group-addon > .ant-btn {
    color: #009c8f !important;
    border-color: #009c8f;
    background-color: #001628;
    border-bottom-width: 2px;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 16px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-bottom: -4px;
}

